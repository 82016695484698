import { useRoutes } from 'react-router-dom';
import router from 'src/router';
import { Provider } from "react-redux";
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { store } from "./state/store";

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import { CssBaseline } from '@mui/material';
import ThemeProvider from './theme/ThemeProvider';
import Login from './content/pages/Status/Login';

function App() {
  let persistor = persistStore(store);
  const content = useRoutes(router);
  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <CssBaseline />
        <Provider store={store}>
          <PersistGate persistor={persistor}>
            {localStorage.getItem('user') ?
              <>{content}</>
            :
              <Login />
            }
          </PersistGate>
        </Provider>
      </LocalizationProvider>
    </ThemeProvider>
  );
}
export default App;
