import { useState } from "react";
import { useNavigate } from "react-router";
import {
    InputAdornment,
    TextField,
  } from '@mui/material';
  import { styled } from '@mui/material/styles';
  import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';


function SearchCustomer() {
    const [customerId, setCustomerId] = useState("");
    const navigate = useNavigate()
    const handleKeyDown = async (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.nativeEvent.isComposing || e.key !== 'Enter') return
        navigate("/management/debtor/details/" + customerId)
        return
      }
    return (
      <>
        <div style={{ display: "flex", alignItems: "center" }}>
            <SearchTwoToneIcon />
            <div  style={{ width: "80%", height: "50px"}}>
                <input type="text"
                    style={{ border: "none", outline: "none", width: "100%", height: "100%", color: "currentColor", padding: "0 10px" }}
                    value={customerId}
                    onChange={(e) => {setCustomerId(e.target.value)}}
                    onKeyDown={handleKeyDown}
                    placeholder="顧客番号"
                />
            </div>
            {/* <SearchInputWrapper
                value={customerId}
                onChange={(e) => {setCustomerId(e.target.value)}}
                onKeyDown={handleKeyDown}
                InputProps={{
                    disableUnderline: true,
                    startAdornment: (
                        <InputAdornment position="start">
                        <SearchTwoToneIcon />
                        </InputAdornment>
                    )
                }}
                placeholder="顧客番号"
                fullWidth
            /> */}
        </div>
      </>
    );
  }
  
  export default SearchCustomer;