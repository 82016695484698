import { ResponseModel } from "src/models/common";
import { BASE_URL, FREEE_URL } from "../utils/AppConst";
import axios from "axios";
import { axiosAuth, handleErrorResponse, handleResponse } from "./common";

  export const LoginAPI = async (username: string, password: string): Promise<ResponseModel> =>  {
    try {
      
      const response = await axios.post(`${BASE_URL}/login`, {
        name: username,
        password: password,
      });
      console.log(response)
     
      return await handleResponse(response);
      
    } catch (e) {
      return handleErrorResponse(e);
    }
  };




