import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Container,
  Divider,
  OutlinedInput,
  IconButton,
  Tooltip,
  FormControl,
  InputAdornment,
  Button,
  FormHelperText,
  TextField
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import Logo from 'src/components/LogoSign';

import { styled } from '@mui/material/styles';

import { LoginAPI } from "../../../../api/user-api";
import { UserModel } from "../../../../models";
import { userLogin } from "../../../../state/reducers/userSlice";
import { useAppSelector } from "../../../../state/hooks";
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
import Stack from '@mui/material/Stack';


const MainContent = styled(Box)(
  () => `
    height: 100%;
    display: flex;
    flex: 1;
    overflow: auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`
);

const TypographyH1 = styled(Typography)(
  ({ theme }) => `
  font-size: ${theme.typography.pxToRem(75)};
`
);

const TypographyH3 = styled(Typography)(
  ({ theme }) => `
  color: ${theme.colors.alpha.black[50]};
`
);

const OutlinedInputWrapper = styled(OutlinedInput)(
  ({ theme }) => `
    background-color: ${theme.colors.alpha.white[100]};
`
);

const ButtonNotify = styled(Button)(
  ({ theme }) => `
    margin-right: -${theme.spacing(1)};
`
);

function StatusLogin() {
  const profile = useAppSelector((state) => state.userReducer.userProfile);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loginErr, setLoginErr] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
      if(localStorage.getItem('user')){
        navigate('/');
      }
  }, [])

  const handleClick = async  () => {
    if (username === "" || password === "") {
      alert("全ての項目を入れてください");
      return;
    }
    setLoading(true);

    const { data, msg } = await LoginAPI(username, password);
    
    if (data) {
      const auth = data.name as UserModel;
      dispatch(userLogin(auth));
      localStorage.setItem('user', data.name);
      localStorage.setItem('userFlg', data.flg);
      localStorage.setItem('token', data.access_token);
      navigate("/");
    }else{
      setLoginErr('ユーザー名またはパスワードが違います');
    }
    setLoading(false);
  }


  return (
    <>
      <Helmet>
        <title>Login</title>
      </Helmet>
      <MainContent>
        <Container maxWidth="md">
          <Logo />
          <Box textAlign="center" mb={3}>
            <Container maxWidth="xs">
              <Typography variant="h1" sx={{ mt: 4, mb: 2 }}>
                ログイン
              </Typography>
            </Container>
          </Box>

          <Container maxWidth="sm">
            <Box sx={{ textAlign: 'center', p: 4 }}>
              <FormControl variant="outlined" fullWidth>
                <TextField
                 sx={{ mb: 2 }}
                  required
                  id="filled-required"
                  label="ユーザー名"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
                <TextField
                  sx={{ mb: 2 }}
                  required
                  id="filled-required"
                  type="password"
                  label="パスワード"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                {/* <Button
                  variant="contained"
                  onClick={() => {handleClick()}}
                >
                  ログイン
                </Button> */}
                <Stack direction="row" spacing={2}>
                  <LoadingButton
                    fullWidth
                    loading={loading}
                    loadingPosition="start"
                    startIcon={<SaveIcon />}
                    variant="contained"
                    onClick={handleClick}
                >
                  ログイン
                </LoadingButton>
              </Stack>
              </FormControl>
              {
                loginErr ? 
                <Box sx={{ color: 'error.main' }}>{loginErr}</Box>
                : ""
              }
            </Box>
          </Container>
        </Container>
      </MainContent>
    </>
  );
}

export default StatusLogin;
