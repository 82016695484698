import {
  Box,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem
} from '@mui/material';
import { useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';

const ListWrapper = styled(Box)(
  ({ theme }) => `
        .MuiTouchRipple-root {
            display: none;
        }
        
        .MuiListItem-root {
            transition: ${theme.transitions.create(['color', 'fill'])};
            
            &.MuiListItem-indicators {
                padding: ${theme.spacing(1, 2)};
            
                .MuiListItemText-root {
                    .MuiTypography-root {
                        &:before {
                            height: 4px;
                            width: 22px;
                            opacity: 0;
                            visibility: hidden;
                            display: block;
                            position: absolute;
                            bottom: -10px;
                            transition: all .2s;
                            border-radius: ${theme.general.borderRadiusLg};
                            content: "";
                            background: ${theme.colors.primary.main};
                        }
                    }
                }

                &.active,
                &:active,
                &:hover {
                
                    background: transparent;
                
                    .MuiListItemText-root {
                        .MuiTypography-root {
                            &:before {
                                opacity: 1;
                                visibility: visible;
                                bottom: 0px;
                            }
                        }
                    }
                }
            }
        }
`
);

function HeaderMenu() {
  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  return (
    <>
      <ListWrapper
        sx={{
          display: {
            xs: 'none',
            md: 'block'
          }
        }}
      >
        <List disablePadding component={Box} display="flex">
          <ListItem
            classes={{ root: 'MuiListItem-indicators' }}
            button
            component="a"
            href="https://www.notion.so/cc0b5b73508349fab1e8edc266bd9f2e?v=364dee39fa3f4b0ba860d1f3d479d8b7"
            target='_blank'
          >
            <ListItemText
              primaryTypographyProps={{ noWrap: true }}
              primary="マニュアル"
            />
          </ListItem>
          {/* <ListItem
            classes={{ root: 'MuiListItem-indicators' }}
            button
            component={NavLink}
            to="/components/forms"
          >
            <ListItemText
              primaryTypographyProps={{ noWrap: true }}
              primary="Forms"
            />
          </ListItem> */}
          <ListItem
            classes={{ root: 'MuiListItem-indicators' }}
            button
            ref={ref}
            onClick={handleOpen}
          >
            <ListItemText
              primaryTypographyProps={{ noWrap: true }}
              primary={
                <Box display="flex" alignItems="center">
                  freee
                  <Box display="flex" alignItems="center" pl={0.3}>
                    <ExpandMoreTwoToneIcon fontSize="small" />
                  </Box>
                </Box>
              }
            />
          </ListItem>
        </List>
      </ListWrapper>
      <Menu anchorEl={ref.current} onClose={handleClose} open={isOpen}>
        <MenuItem
          sx={{ px: 3 }}
          component="a"
          href="https://accounts.secure.freee.co.jp/login/accounting?o=true&a=false&e=0"
          target='_blank'
        >
          TOP
        </MenuItem>
        <MenuItem
          sx={{ px: 3 }}
          component="a"
          href="https://app.secure.freee.co.jp/developers/start_guides/new_company"
          target='_blank'
        >
          API設定
        </MenuItem>
        <MenuItem
          sx={{ px: 3 }}
          component="a"
          href="https://accounts.secure.freee.co.jp/public_api/authorize?client_id=8593493659e7730fb08e45b8dcb4c87d4863b2c1318ab767bda8878b4742eba8&redirect_uri=https%3A%2F%2Fapp.secure.freee.co.jp%2Fdevelopers%2Fstart_guides%2Fapplications%2F24544%2Ftoken%3Fcompany_id%3D10779999&response_type=token"
          target='_blank'
        >
          トークン
        </MenuItem>
   
      </Menu>
    </>
  );
}

export default HeaderMenu;
