import axios, { AxiosResponse } from "axios";
import { ResponseModel } from "src/models/common";
import { BASE_URL } from "../utils/AppConst";
import { useNavigate } from "react-router";



export const handleResponse = async (
  response: AxiosResponse
): Promise<ResponseModel> => {
  if (response.status === 200) {
    return {
      // msg: "success",
      msg: null,
      data: response.data,
    };
  }
  return { 
    data: null,
    msg: "エラーが発生しました" 
  };
};

export const handleErrorResponse = async (
  e: any
): Promise<ResponseModel> => {

  if(e.response) {
    // if(e.response.data.error === 'authorization header is not provided') {
    if(e.response.status == 401) {
      localStorage.removeItem('user');
      localStorage.removeItem('userFlg');
      localStorage.removeItem('token');
      
      window.location.href = '/login';
      return {
        data: null,
        msg: "セッションが切れました。ログインページに戻ります。"
      };
    }else {
      return {
        data: null,
        msg: e.response?.data.error
      };
    }
  }else {
    return { 
      data: null,
      msg: "エラーが発生しました" 
    };
  }

};

export const axiosAuth = (token: string) => {
  
  return axios.create({
    baseURL: BASE_URL,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
};