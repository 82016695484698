// Develop
export const BASE_URL = 
// "http://localhost:8080";
// <---- Develop ---->
// "https://p7vm28n5a6.ap-northeast-1.awsapprunner.com";

// <---- Production ---->
"https://epxmunpi6p.ap-northeast-1.awsapprunner.com";

// export const TRANSACTION_URL =
//   "https://4favyg1qn0.execute-api.eu-central-1.amazonaws.com/prod";
export const FREEE_URL = "https://api.freee.co.jp/api/1";
export const LISTERM_URL = "https://listerm.com";
export const MYPAGE_URL = "https://devdevecstesterm.net"

// "http://localhost:8888";
export const COMPANY_ID = 10743762;
export const S3_URL = "https://apprunner-choikari-documents.s3.ap-northeast-1.amazonaws.com/";